import { addTagManager, addMaps, addManifest } from './integrations.js';

const NS_ACCOUNTS = {
  maps: 'AIzaSyBbwHv8kb-2-lsQBdyHefGpEycV-TX22iI',
  GTMLoaderId: 'GTM-K9B69L9',
  customGTMLoaderUrl: 'https://staging-tneve.newsilver.com',
  customGTMLoaderFile: 'bxptxsdtzjp.js',
  customGTMParams: '3rr1=aWQ9R1RNLUs5QjY5TDk%3D&page=1',
  manifest: 'https://staging-backend.newsilver.com',
};

const WL_ACCOUNTS = {
  maps: 'AIzaSyBbwHv8kb-2-lsQBdyHefGpEycV-TX22iI',
  GTMLoaderId: 'GTM-N5PTNN7V',
  customGTMLoaderUrl: 'https://staging-tneve.fastloanapp.com',
  customGTMLoaderFile: '3bfuzshqnxue.js',
  customGTMParams: 'k=aWQ9R1RNLU41UFROTjdW&apiKey=37d753b3',
  manifest: 'https://staging-backend.fastloanapp.com',
};

const ACCOUNTS = getProduct() === 'ns' ? NS_ACCOUNTS : WL_ACCOUNTS;

addTagManager(
  ACCOUNTS.customGTMLoaderUrl,
  ACCOUNTS.customGTMLoaderFile,
  ACCOUNTS.customGTMParams,
  ACCOUNTS.GTMLoaderId
);
addMaps(ACCOUNTS.maps);
addManifest(ACCOUNTS.manifest);

window.getApplicationLink = (subDomain) => {
  const splitHost = location.hostname.split('.');
  subDomain = subDomain || splitHost[0];
  const domain = `${splitHost[splitHost.length - 2]}.${
    splitHost[splitHost.length - 1]
  }`;
  if (subDomain === 'cc') {
    return '';
  }

  return `https://${subDomain}.staging.${domain}`;
};
