import { addTagManager, addMaps, addManifest } from './integrations.js';

const NS_ACCOUNTS = {
  maps: 'AIzaSyBbwHv8kb-2-lsQBdyHefGpEycV-TX22iI',
  tagManager: 'GTM-K9B69L9',
  manifest: 'https://staging-backend.newsilver.com',
};

const WL_ACCOUNTS = {
  maps: 'AIzaSyBbwHv8kb-2-lsQBdyHefGpEycV-TX22iI',
  tagManager: 'GTM-N5PTNN7V',
  manifest: 'https://staging-backend.fastloanapp.com',
};

const ACCOUNTS = getProduct() === 'ns' ? NS_ACCOUNTS : WL_ACCOUNTS;

addTagManager(ACCOUNTS.tagManager);
addMaps(ACCOUNTS.maps);
addManifest(ACCOUNTS.manifest);

window.getApplicationLink = (subDomain) => {
  const splitHost = location.hostname.split('.');
  subDomain = subDomain || splitHost[0];
  const domain = `${splitHost[splitHost.length - 2]}.${
    splitHost[splitHost.length - 1]
  }`;
  if (subDomain === 'cc') {
    return '';
  }

  return `https://${subDomain}.staging.${domain}`;
};
